import {createApp} from 'vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import VueResizeObserver from 'vue-resize-observer';
import VueLazyload from 'vue-lazyload';
import PrimeVue from 'primevue/config';
import VueVirtualScroller from 'vue-virtual-scroller';
import 'boxicons';

import App from './App.vue';
import router from './router';
import store from './store';

import Chips from 'primevue/chips';

// Feather Icon
import FeatherIcon from './components/Icon/FeatherIcon.vue';
// Font awesome Icon
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

library.add(fas);
library.add(fab);

import {RecycleScroller} from 'vue-virtual-scroller';
import {PerfectScrollbar} from 'vue3-perfect-scrollbar';

// Css
import 'primeicons/primeicons.css';
import 'primevue/resources/themes/lara-light-indigo/theme.css';
import 'primevue/resources/primevue.min.css';
import './config/theme/style.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

/// Legacy
import 'daterangepicker/daterangepicker.js';
import 'daterangepicker/daterangepicker.css';

// Custom Element
import './views/Location/CustomInfoWindow/index.js';

const app = createApp(App);
app.config.productionTip = false;
app.config.devtools = true;
// app.config.compilerOptions.isCustomElement = (tag) => tag.startsWith('custom-');

app.use(store);
app.use(router);
app.use(Antd);
app.use(PrimeVue);
app.use(VueResizeObserver);
app.use(VueVirtualScroller);
app.use(VueLazyload, {
    adapter: {
        error(listender) {
            const className = listender.el.className
            console.log(listender.el)
            if (className === 'thumb-img' || className === 'media-img') {
                const failedImageUrl = listender.src
                const fallbackImage = listender.el.attributes['fallback-src'].value
                if (failedImageUrl !== fallbackImage) {
                    listender.el.src = fallbackImage
                }
            }
        }
    }
});
app.component('FeatherIcon', FeatherIcon);
app.component('FontAwesomeIcon', FontAwesomeIcon);
app.component('RecycleScroller', RecycleScroller);
app.component('PerfectScrollbar', PerfectScrollbar);
app.component('Chips', Chips);

app.mount('#app');
