import { mapChannelListToTable } from '@/utils/Campaigns';

export function generateChannelColumns() {
  const channels = [
    {
      // title: 'Share of Voice (SOV)',
      dataIndex: 'type',
      key: 'type',
      fixed: 'left',
      // width: 250,
      slots: { title: 'customTypeTitle', customRender: 'type' },
    },
  ];
  const channelsInfo = [
    {
      // title: 'Facebook'
      key: 'facebook',
      slots: { title: 'customFacebookTitle' },
    },
    {
      // title: 'Twitter',
      key: 'twitter',
      slots: { title: 'customTwitterTitle', customRender: 'twitter' },
    },
    {
      // title: 'Instagram',
      key: 'instagram',
      slots: { title: 'customInstagramTitle', customRender: 'instagram' },
    },
    {
      // title: 'Youtube',
      key: 'youtube',
      slots: { title: 'customYoutubeTitle', customRender: 'youtube' },
    },
    {
      // title: 'Pantip',
      key: 'pantip',
      slots: { title: 'customPantipTitle', customRender: 'pantip' },
    },
    {
      // title: 'Blockdit',
      key: 'blockdit',
      slots: { title: 'customBlockditTitle', customRender: 'blockdit' },
    },
    {
      // title: 'Tiktok',
      key: 'tiktok',
      slots: { title: 'customTiktokTitle', customRender: 'tiktok' },
    },
    {
      // title: 'Website',
      key: 'website',
      slots: { title: 'customWebsiteTitle', customRender: 'website' },
    },
  ].map(mapChannelListToTable);
  for (const c of channelsInfo) {
    channels.push(c);
  }
  return channels;
}
